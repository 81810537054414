@tailwind base;

body {
  font-family: 'Open Sans', sans-serif;
}

/* 
need this because the UV has its own .hidden class that overrides
tailwind's .hidden when the UV loads.
*/
.hide {
  display: none;
}

h1 {
  @apply text-3xl font-bold;
  font-family: sofia-pro, sans-serif;
}

h2 {
  @apply text-xl;
  font-family: sofia-pro, sans-serif;
}

h3 {
  @apply text-lg;
  font-family: sofia-pro, sans-serif;
}

a {
  @apply text-primary-500 font-bold;
  font-family: sofia-pro, sans-serif;
}

button {
  font-family: sofia-pro, sans-serif;
}

ul {
  list-style: disc;
  @apply pl-4;
}

ol {
  list-style: disc;
  @apply pl-4;
}

blockquote {
  border-left: 4px solid #718096; /* text-gray-600 */
  @apply pl-3;
}

.import-items .item.new {
  @apply bg-secondary-400;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
}

.skeleton-box:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.35) 40%,
    rgba(255, 255, 255, 0.35) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 3s infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@tailwind components;
@tailwind utilities;